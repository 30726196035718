import React from 'react'
import { Link } from 'react-router-dom'
const OurLeadership = () => {
  return (
    <div>
        <section class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <h6 class="text-gradient text-info text-uppercase">The Executive Team</h6>
          <h2>Building the future</h2>
        </div>
      </div>
      <div class="row mt-md-5 mt-4">
        <div class="col-lg-3 col-6 mb-lg-0 mb-4">
          <div class="card shadow-xl">
            <div class="card-body text-center bg-white border-radius-lg p-3">
              <Link to="/OurLeadershipInside">
                <img class="w-100 border-radius-md" src="../../assets/img/K7it.png"/>
              </Link>
              <h5 class="mt-3 mb-1 d-md-block d-none">Kesavulu Arthala</h5>
              <p class="mb-0 text-xs font-weight-bolder text-info text-gradient text-uppercase">Founder & CEO</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}

export default OurLeadership